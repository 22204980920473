.show-on-mobile{
    display: none; 
  }
.detail-on-mobile{
     display: none; 
}

@media only screen and (max-width: 768px) {
  .hide-on-mobile {
    display: none; 
   
  }

  .show-on-mobile{
    display: inline; 
    text-align: center;
    
  }
  .custom-grid {
    
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 10px;
  }

  .detail-on-mobile{
    
    display: inline; 
    
  }
  
  .f-size-on-mobile {
    font-size: smaller;
  }
  .custom-grid-item {
    
    width: 100%;
  }
}
.serviceitem:hover {
  zoom: 1.0;
  font-weight: bold;
  background-image: linear-gradient(to right, #4DD6F4, #4DD6F4, #4DD6F4);
  color: white;
}

.selected {
  background-color: #e6e6e6; /* Change background color as desired */
}